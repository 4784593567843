import React from 'react';
import { Result, Avatar, Typography, Button, Form, Modal, Input, Space, Row, Col, Divider } from 'antd';
import { UserOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';

const { Paragraph, Text, Link } = Typography;

const Global = props => {
    return (
        <>
        {props.state.modal === 'upd_pass' &&
                <Modal key="m3" title="Смена пароля" visible={props.state.modal === 'upd_pass'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                    <Form name="form_0_1" onFinish={props.f_0_1} className="form">
                        <Text type="warning">Пароль должен быть от 5 до 20 символов, содержать хотя бы одну маленькую, одну большую букву и одну цифру.</Text>
                        <Form.Item name="pasu" label="Пароль" >
                            <Input.Password type="password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">Сохранить</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            }
            <Row style={{justifyContent:'center'}}>
                <Col flex="0 0 900px" style={{padding:20}} className="shadowscreen" >
                    <Result
                        icon={props.state.profile.ava !== "" ? <Avatar size={128} src={`${props.state.app.res}/img/${props.state.profile.ava}`} /> : <Avatar style={{ backgroundColor: props.randcolor() }} size={128} icon={<UserOutlined />} />}
                        title={props.state.profile.name}
                        subTitle={"В данном разделе можно увидеть основную информацию о учётной записи и поменять пароль."}
                    />
                    <div className="coldesc">
                        <Paragraph>
                            <Text
                                strong
                                style={{
                                    fontSize: 16,
                                }}
                            >
                                Основная информация:
                            </Text>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                {props.state.profile.phone &&<Text>Ваш номер телефона для связи: <Link href={`tel: +${props.state.profile.phone}`}>+{props.state.profile.phone}</Link></Text>}
                                {props.state.profile.email.length > 5 &&<Text>Ваша электронная почта для писем: <Link href={`mailto: ${props.state.profile.email}`}>{props.state.profile.email}</Link></Text>}
                            </Space>
                        </Paragraph>
                        <Divider />
                        <Paragraph>
                            <Text
                                strong
                                style={{
                                    fontSize: 16,
                                }}
                            >
                                Управление:
                            </Text>
                        </Paragraph>
                        <Paragraph>
                            <Button shape="round" onClick={() => props.modal('upd_pass')}>Сменить пароль</Button>
                        </Paragraph>
                    </div>
                </Col>
            </Row>    
        </>
    )
};

export default Global;