import React from 'react';
import { Typography, Card, List, Tooltip, Button, Space, Row, Col } from 'antd';
import {  } from '@ant-design/icons';

const { Meta } = Card;
const { Link } = Typography;

const Global = props => {
    return (
        <>
            <List className="flexafter"
                locale={{ emptyText: <span className="tableempty">Список пуст</span>}}
                grid={{ gutter: 16 }}
                dataSource={props.state.proj1[1]}
                renderItem={item => (
                  <List.Item>
                    <Card
                      className="newcard"
                      bordered={false}
                      hoverable
                      style={{ width: 300 }}
                      cover={
                        <img className="of" onClick={() => props.api({ 'method':'site_get', 'site':`${item.id}` }) } alt="" src={`${props.state.app.res}/img/${item.img}`} />
                      }
                    >
                      <Meta
                        title={item.text}
                        description={
                          <Space direction="vertical">
                          {item.name}
                            <Row gutter={[16, 16]}>
                              <Col span={12} >
                                <Tooltip title={`Перейти на сайт «${item.name}»`}><Link target="_blank" rel="noopener noreferrer" href={`https://${item.name}`}><Button className="grad" block shape="round">Открыть</Button></Link></Tooltip>
                              </Col>
                              <Col span={12} >
                                <Tooltip title={`Открыть подробную информацию о «${item.name}»`}><Link key="a2" onClick={() => props.api({ 'method':'site_get', 'site':`${item.id}` }) }><Button className="grad" block shape="round">Подробно</Button></Link></Tooltip>
                              </Col>
                            </Row>
                          </Space>
                        }
                      />
                    </Card>
                  </List.Item>
                )}
              />  
        </>
    )
};

export default Global;