import React from 'react';
import { Typography, Tooltip, Space, Table, Row, Col } from 'antd';
import { DownloadOutlined, CarOutlined  } from '@ant-design/icons';

const { Title, Link } = Typography;

const Global = props => {

  const track = [
    {
        title: '',
        url: ''
    },{
        title: 'cdek.ru',
        url: 'https://www.cdek.ru/ru/tracking?order_id='
    },{
        title: '',
        url: 'https://www.pochta.ru/tracking#'
    }
  ];

  const columns1 = [
    {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
        width: 100,
        sorter: (a, b) => a.name.localeCompare(b.name),
      },{
        title: 'Дата',
        dataIndex: 'date',
        key: 'date',
        render: (text) => props.lasttime(text,'Неизвестно когда','full'),
        width: 100,
        sorter: (a, b) => a.date.localeCompare(b.date),
    },{
        title: 'Действия',
        key: 'action',
        render: (record) => (
            <Space key={record.id} size="middle">
                <Tooltip title={`Скачать «${record.name}»`}><Link key="a1" onClick={() => props.api({ 'method':'docs_get', 'docid':`${record.id}` })}><DownloadOutlined /></Link></Tooltip>
            </Space>
        ),
        width: 100,
      }
  ];

  const columns2 = [
    {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
        width: 100,
        sorter: (a, b) => a.name.localeCompare(b.name),
      },{
        title: 'Дата',
        dataIndex: 'date',
        key: 'date',
        render: (text) => props.lasttime(text,'Неизвестно когда','full'),
        width: 100,
        sorter: (a, b) => a.date.localeCompare(b.date),
    },{
        title: 'Статус',
        key: 'tstatus',
        render: (record) => record.tstatus === '1' ? <>Отправлен {props.lasttime(record.tdate,'неизвестно когда','full')}</>:'Не отправлен',
        width: 100,
        sorter: (a, b) => a.tstatus.localeCompare(b.tstatus),
    },{
        title: 'Действия',
        key: 'action',
        render: (record) => (
            <Space key={record.id} size="middle">
                <Tooltip title={`Скачать «${record.name}»`}><Link key="a1" onClick={() => props.api({ 'method':'docs_get', 'docid':`${record.id}` })}><DownloadOutlined /></Link></Tooltip>
                {(record.ttracker && record.ttype) && <Tooltip title={`Открыть отслеживание «${record.name}»`}><Link href={track[record.ttype]['url']+record.ttracker} target="_blank" key="a1" ><CarOutlined /></Link></Tooltip>}
            </Space>
        ),
        width: 100,
      }
  ];

    return (
        <Row>
            <Title className="gray" level={4}>Список договоров</Title>
            <Col span={24} style={{padding:20}} className="shadowscreen" >
                <Table locale={{ emptyText: <span className="tableempty">Список пуст</span>}} dataSource={props.state.docs[0]} scroll={{ x: 500 }} rowKey="table2" columns={columns1}></Table>
            </Col>
            <Title className="gray" style={{marginTop:50}} level={4}>Список АВР</Title>
            <Col span={24} style={{padding:20}} className="shadowscreen" >
                <Table locale={{ emptyText: <span className="tableempty">Список пуст</span>}} dataSource={props.state.docs[1]} scroll={{ x: 500 }} rowKey="table2" columns={columns2}></Table>
            </Col>
        </Row>      
    )
};

export default Global;