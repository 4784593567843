import React from 'react';

import 'antd/dist/antd.css';
import './App.css';
import './fonts/fonts.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Layout, message, Spin, Tabs, Tooltip, Modal, Typography } from 'antd';
import { HomeOutlined, AppstoreOutlined, SettingOutlined, ImportOutlined, LoadingOutlined } from '@ant-design/icons';

import Auth 		                        from './components/Auth/Auth';
import GlobLoadpage 		      from './components/Global/Loadpage';
import GlobLeftmenu 		       from './components/Global/Leftmenu';
import GlobHeader 		           from './components/Global/Header';
import HomeHome                from './components/Home/Home'; 
import CliSites                        from './components/Clients/Sites'; 
import CliView                         from './components/Clients/View'; 
import CliSocials                     from './components/Clients/Socials';
import ServicesServices        from './components/Services/Services'; 
import DocsDocs                   from './components/Docs/Docs'; 
import SetProfile 		                from './components/Settings/Profile';

const { Link } = Typography;
const { Content } = Layout;
const TabPane = Tabs.TabPane;

class App extends React.Component {
  constructor(props) {
		super(props);

    this.state = {
      auth: 0,
      error: ['',''],
      menu: {"-1":'Главная',"-2":'Настройки',"-3":"Документы","-4":"Услуги","1":'Сайты',"0":'Выйти'},
      profile: {'login':'','name':'','ava':''},
      proj1: ['1',[],[],[]],       // 0-Tab,1-список сайтов,сайт,2-список соц сетей
      setting: ['1'],                                       // 0- Tab,
      docs: [],
      services: [],
      activemenu: "-1",
      loading: true,
      modal: '',
      filteredInfo: null,
      sortedInfo: null,
      app: {},
      visible: [],
      home: [],
      width: 0,
    };
  }

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  //* Вызов сразу после рендера
  async componentDidMount() {
    let app = this.state.app;
    if (document.domain === 'localhost') {
      app['url'] = 'https://lk.rus2.ru';
      app['res'] = 'https://adm.rus2.ru';
    } else {
      app['url'] = `https://${document.domain}`;
    }
    await this.setState( { app:app } );
    if (this.getCookie('token') !== undefined) {
      await this.login(1,1,this.getCookie('token'));
    } else {
      await this.setState( { auth:1 } );
    }
  }

  totime() {
    let date = new Date();
    let d1 = (date.getDate()<10?'0':'') + date.getDate();
    let d2 = date.getMonth()+1;
    d2 = (d2<10?'0':'') + d2;
    let d3 = date.getFullYear();
    let d4 = (date.getHours()<10?'0':'') + date.getHours();
    let d5 = (date.getMinutes()<10?'0':'') + date.getMinutes();
    return `${d1}.${d2}.${d3} - ${d4}-${d5}`;
  }

  //* POST запрос
  postRequest(url, data) {
    return fetch(url, {
      credentials: 'same-origin',
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
    })
    .then(response => response.json())
  }

  //* Вход
  async login (l,p,t) {
    await this.setState( { auth: 0 } );
    let user = { login: l, pass: p, method: 'auth' };
    if (t) {
      user = { token: t, method: 'auth' };
    }
    
    await this.postRequest(`${this.state.app.url}/api_v2/`, user)
    .then(async json => {
      var data = json;
      if(data.code) {
        let profile 		= ( data.profile ) ? data.profile : {};
        let app         = ( data.app ) ? data.app : {};
        let visible     = ( data.visible ) ? data.visible : [];
        let home   = ( data.list ) ? data.list : [];
        await this.setState( {
          profile: profile,
          app: app,
          visible: visible,
          home: home
        } );
        if (!t) {
          this.setCookie('token', data.profile.token);
          message.success('Вы успешно вошли!');
        }

        await this.setState( {
          auth:2,
          loading: false
        } );
      }
      if(data.error) {
        await this.setState( { auth:1, error: data.error } );
        message.error(`${data.error[0]}${data.error[1].length > 0 ? ` - ${data.error[1]}`:''}`);
      }
    })
    .catch( async err => {
      await this.setState( { auth:1, error: ['Проблемы со связью',''] } );	//Проблемы со связью
    });
  }

  //* Получение информации
  async api(v) {
    await this.setState( { loading: true, modal:'' } );
    v['token'] = this.getCookie('token');
    await this.postRequest(`${this.state.app.url}/api_v2/`, v)
    .then(async json => {
      var data = json;
      if(data.code) {
        //* Профиль
        let profile 			= ( data.profile ) ? data.profile : {};
        let app          = ( data.app ) ? data.app : [];
        let visible          = ( data.visible ) ? data.visible : [];
        let home   = ( data.list ) ? data.list : [];
        await this.setState( {
          profile: profile,
          app: app,
          visible: visible,
          home: home
        } );

        //* Методы
        switch(v['method']) {
          case 'list_site':
            var proj1 = this.state.proj1
            var clicat  = ( data.list ) ? data.list : [];
            proj1[1] = clicat;
            await this.setState( {
              proj1: proj1
            } );
            break;
          case 'site_get':
            proj1 = this.state.proj1
            var get  = ( data.get ) ? data.get : [];
            proj1[0] = '3';
            proj1[2] = get;
            await this.setState( {
              proj1: proj1
            } );
            break;
          case 'rep_get':
            Modal.success({content: (<><p>Ссылка получена!</p><Link href={data.url} target="_blank" rel="noopener noreferrer">Скачать</Link></>),});
            break;
          case 'sett_upd_pass':
            message.success('Пароль обновлён!');
            break;
          case 'docs_list':
            get  = ( data.list ) ? data.list : [];
            await this.setState( { docs:get } );
            break;
          case 'docs_get':
            Modal.success({content: (<><p>Ссылка получена!</p><Link href={`${this.state.app.res}/docs/${data.url}`} target="_blank" rel="noopener noreferrer">Скачать</Link></>),});
            break;
          case 'services':
            get  = ( data.list ) ? data.list : [];
            await this.setState( { services:get } );
            break;
          default: break;
        }
      }
      if(data.error) {
        await this.setState( { error: data.error } );
        message.error(`${data.error[0]}${data.error[1].length > 0 ? ` - ${data.error[1]}`:''}`);
      }
    })
    .catch( async err => {
      await this.setState( { error: ['Проблемы со связью',''] } );	//Проблемы со связью
    });
    await this.setState( { loading: false } );
  }

  //* Получение нужного куки
  getCookie(name) {
    let matches = document.cookie.match(new RegExp( "(?:^|; )" + name.replace(/([$?*|{}\]\\^])/g, '\\$1') + "=([^;]*)" ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  //* Линк на пункт меню
  menulink = (e) => { let id = e.currentTarget.dataset.id; this.menugo(id); }

  //* Доп функция для меню
  async menu(a) { await this.menugo(a.key); }
  
  //* Обнуление Tab разделов
  async deltabs() {
    let proj1 = this.state.proj1;
    proj1[0] = '1';
    await this.setState( { proj1:proj1} );
  }

  //* Переход на другой раздел
	async menugo(a) {
    await this.setState( { loading: true } );
    await this.deltabs();
    await this.setState( { activemenu:a } );
    switch(a) {
      case '0':
        await this.delCookie('token');
        await this.setState( {auth: 1, error: ['','']} );
        await message.success('Вы успешно вышли! Пожалуйста, подождите пару секунд.');
        await window.location.reload();
        break;
      case '-1':
        await this.api({ 'method':'auth' });
        break;
      case '-2':
        await this.api({ 'method':'auth' });
        break;
      case '-3':
        await this.api({ 'method':'docs_list' });
        break;
      case '-4':
          await this.api({ 'method':'services' });
          break;
      case '1':
        await this.api({ 'method':'list_site' });
        break;
      default:  break;
    }
    await this.setState( { loading: false } );
  }

  //* Добавление куки
  setCookie(name, value, options = {}) {
    options = { path: '/', ...options };
    if (options.expires instanceof Date) { options.expires = options.expires.toUTCString(); }
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) { updatedCookie += "=" + optionValue; }
    }
    document.cookie = updatedCookie;
  }

  //* Сортировка
  htc = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  //* Удаление куки
  delCookie (name) { this.setCookie(name, "", { 'max-age': -1 }) };

  //* Случайный цвет
  randcolor() {
    var arr = ['#cc0029', '#E14015', '#f56a00', '#FA9500', '#ffbf00', '#9EB51E', '#3caa3c', '#1EA675', '#00a2ae', '#42aaff', '#2E82E8', '#1959d1', '#7265e6'];
    var rand = Math.floor(Math.random() * arr.length);
    return arr[rand];
  }

  //* Приветствие
  hello() {
    var MyDate = new Date(),
    MyHours = MyDate.getHours(),
    name = this.state.profile.name,
    hello = 'Здравствуйте, ';
    switch (true){
      case (MyHours >= 5) && (MyHours < 11):hello = 'Доброе утро, ' + name;
      break;
      case (MyHours >= 11) && (MyHours < 16):hello = 'Добрый день, ' + name;
      break;
      case (MyHours >= 16) && (MyHours <= 23):hello = 'Добрый вечер, ' + name;
      break;
      case (MyHours >= 0) && (MyHours < 5):hello = 'Доброй ночи, ' + name;
      break;
      default:hello = 'Здравствуйте, ' + name;
      break;
    }
    return hello;
  }

  //* Смена вкладки Tab клиентов
  async changetab1(v) {
    switch (v) {
      case '1':
        await this.api({ 'method':'list_site' });
        break;
      default: break;
    }
    let proj1 = this.state.proj1;
    proj1[0] = v;
    proj1[5] = {};
    await this.setState( { proj1:proj1 } );
  }

  //* Готовые иконки
  icons(n) {
    switch(n) {
      case '-1': return <HomeOutlined />;
      case '1': return <AppstoreOutlined />;
      case '-2': return <SettingOutlined />;
      case '0': return <ImportOutlined />;
      default: return <AppstoreOutlined />;
    }
  }

  //* Обновление пароля
  async changesett(v) {
    v['method'] = 'sett_upd_pass';
     await this.api(v);
  }

  //* Модальные окна
  async modal(t) {
    this.setState( { modal:t } );
  }

  decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }

  //* Проекты
  userprojchange(value) {}

  //* Дата
  lasttime(time,empty,type) {
    let newtime 	= new Date();
		let oldtime 	= new Date();
		let notiftime 	= new Date( time * 1000 );
		newtime.setMinutes( newtime.getMinutes() );
		oldtime.setMinutes( newtime.getMinutes() - 1440 );
		notiftime.setMinutes( notiftime.getMinutes());
		let newdate 	= Math.floor( newtime.getTime()/1000 );
		let notifdate 	= Math.floor( notiftime.getTime()/1000 );
		let diff 		= newdate-notifdate;
		let monthlist 	= { "00":"янв ", "01":"фев ", "02":"мар ", "03":"апр ", "04":"мая ", "05":"июн ", "06":"июл ", "07":"авг ", "08":"сен ", "09":"окт ", "10":"ноя ", "11":"дек " };
		let info 		= '';
		if ( diff < 60 ) 	{ info = Math.floor( diff ) + this.decnum( Math.floor( diff ),[ ' секунду ',' секунды ',' секунд ' ] ) + " назад "; }
		if ( diff > 60 ) 	{ info = Math.floor( diff/60 ) + this.decnum( Math.floor( diff/60 ),[ ' минуту ',' минуты ',' минут '] ) + " назад "; }
		if ( diff > 1800 ) 	{ info = "полчаса назад "; }
		if ( diff > 3600 ) 	{ info = "час назад "; }
		if ( diff > 7200 ) 	{ info = "2 часа назад "; }
		if ( diff > 10800 ) { info = "3 часа назад "; }
		if ( diff > 14400 ) {
			if ( ( '0' + notiftime.getDate() ).slice( -2 ) === ( '0' + newtime.getDate() ).slice( -2 ) ) {
				info = "сегодня в " + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + " "; 
			} else {
				info = "вчера в " + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + " ";
			}
		}
		if ( diff > 24*3600 ) {
			if ( ('0' + notiftime.getDate()).slice(-2) === ('0' + oldtime.getDate()).slice(-2) ) {
				info = "вчера в " + ('0' + notiftime.getHours()).slice(-2) + ':' + ('0' + notiftime.getMinutes()).slice(-2) + " "; 
			} else {
				info = ('0' + notiftime.getDate()).slice(-2) + ' ' + monthlist[('0' + notiftime.getMonth()).slice(-2)] + ' в ' + ('0' + notiftime.getHours()).slice(-2) + ':' + ('0' + notiftime.getMinutes()).slice(-2) + ' ';
			}
		}
		if ( diff > 2*24*3600 ) { info = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года в ' + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + ' '; }
		let diffrev = notifdate-newdate;
		if ( diff === 0 ) 	{ info = "только что "; }
		if (diffrev > 0) { info = info = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года в ' + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + ' '; }
		let full = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года в ' + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + ' ';
		switch (type) {
      case 'last':
        full = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года ';
        info = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года ';
        break;
      default: break;
    }
    if (time === '0') {
      info = full = empty;
    }
    return( <Tooltip title={full}>{info}</Tooltip> );
  }

  //* Дату в секунды
  toTimestamp(strDate){
    var datum = Date.parse(strDate);
    return datum/1000;
 }

  //* Рендер
  render() {
    const spin = <div className="spincenter"><Spin indicator={<LoadingOutlined style={{ color:"#ac8df0",fontSize: 64 }} spin />} /></div>;
    const f_Login = (v) => { this.login(v['username'],v['password']); };
    const f_4_1 = (v) => { this.clicadd(v); };
    const f_4_3 = (v) => { this.siteservadd(v); };
    const f_4_4 = (v) => { this.siteseoadd(v); };
    const f_4_5 = (v) => { this.siterepadd(v); };
    const f_4_6 = (v) => { this.sitedom(v); };
    const f_4_7 = (v) => { this.sitehisadd(v); };
    const f_0_1 = (v) => { this.changesett(v); };
    const call_4_1 = (v) => { this.changetab1(v); };

    return (
      <Layout>
        {this.state.auth === 0 && <GlobLoadpage />}
        {this.state.auth === 2 && <GlobLeftmenu state={this.state} menu={this.menu.bind(this)} icons={this.icons} />}
        <Router>
          <Switch>
            <Route exact path="/">
              {this.state.auth === 1 && <Redirect to="/login"/> }
            </Route>
            <Route path="/login">
              {this.state.auth === 1 ? <Auth f_Login={f_Login} />:<Redirect to="/"/>}
            </Route>
            <Redirect from='*' to='/'/>
          </Switch>
        </Router>
        
        {this.state.auth === 2 &&
          <Layout className="homescreen" style={{ minHeight: "100vh" }}>
            <GlobHeader state={this.state} menu={this.menu.bind(this)} icons={this.icons} />
            <Content style={{ margin: '24px 16px 0' }}>
            {!this.state.loading ?
                  <div className="site-layout-background" style={{ padding: 24 }}>
                    {this.state.activemenu === "-1" &&
                      <HomeHome setState={this.setState.bind(this)} state={this.state}  icons={this.icons} menulink={this.menulink} hello={this.hello} lasttime={this.lasttime.bind(this)} randcolor={this.randcolor} />
                    }
                    {this.state.activemenu === "-2" &&
                      <SetProfile setState={this.setState.bind(this)} state={this.state} randcolor={this.randcolor} modal={this.modal} f_0_1={f_0_1} />
                    }
                    {this.state.activemenu === "-3" &&
                      <DocsDocs setState={this.setState.bind(this)} state={this.state} randcolor={this.randcolor} modal={this.modal} lasttime={this.lasttime.bind(this)} api={this.api.bind(this)} />
                    }
                    {this.state.activemenu === "-4" &&
                      <ServicesServices setState={this.setState.bind(this)} state={this.state} randcolor={this.randcolor} modal={this.modal} lasttime={this.lasttime.bind(this)} api={this.api.bind(this)} />
                    }
                    {this.state.activemenu === "1" &&
                      <Tabs defaultActiveKey="1" onChange={call_4_1} activeKey={this.state.proj1[0]}>
                        <TabPane tab="Сайты" key="1">
                          {this.state.proj1[0] === '1' ? <CliSites state={this.state} f_4_1={f_4_1} api={this.api.bind(this)} setState={this.setState.bind(this)} yauploadserv={this.yauploadserv} lasttime={this.lasttime.bind(this)} /> : spin}
                        </TabPane>
                        <TabPane tab="Просмотр сайта" key="3" disabled>
                          {this.state.proj1[0] === '3' ? <CliView state={this.state} setState={this.setState.bind(this)} yauploadrep={this.yauploadrep} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} f_4_3={f_4_3} f_4_4={f_4_4} f_4_5={f_4_5} f_4_6={f_4_6} f_4_7={f_4_7} /> : spin}
                        </TabPane>
                      </Tabs>
                    }
                    {this.state.activemenu === "2" &&
                      <Tabs defaultActiveKey="1" >
                        <TabPane tab="Соц сети" key="1">
                          <CliSocials state={this.state} api={this.api.bind(this)} setState={this.setState.bind(this)} yauploadserv={this.yauploadserv} lasttime={this.lasttime.bind(this)} />
                        </TabPane>
                      </Tabs>
                    }
                  </div>
                  :
                  spin
                }
            </Content>
          </Layout>
        }
      </Layout>
    );
  }
}

export default App;
