import React from 'react';
import { Typography, Card, List, Space, Divider, Row, Col, Tooltip, Button  } from 'antd';
import {   } from '@ant-design/icons';

const { Text,Title, Link } = Typography;
const { Meta } = Card;

const Global = props => {
    return (
        <>
          { props.state.services.map( (list, index) => 
            <div className="servicegroup">
              <Title className="gray" level={4}>{list[0]}</Title>
                <List className="flexafter"
                  locale={{ emptyText: <span className="tableempty">Список пуст</span>}}
                  grid={{ gutter: 16 }}
                  dataSource={list[1]}
                  renderItem={item => (
                  <List.Item style={{display:'flex'}}>
                    <Card
                      className="newcard"
                      bordered={false}
                      hoverable
                      style={{ width: 300 }}
                      cover={
                        <img className="of" alt="" src={`${props.state.app.res}/img/${item.img}`} />
                      }
                    >
                      <Meta
                        description={
                          <Space style={{textAlign:'left'}} direction="vertical">
                            <Row gutter={[16, 16]}>
                              <Col span={14}  >
                                <Text strong className="gray2">{item.name}</Text>
                              </Col>
                              <Col span={10} >
                                <Button className="grad" block shape="round">{item.cost} ₽</Button>
                              </Col>
                            </Row>
                            <Text className="gray">{item.desc}</Text>
                          </Space>
                        }
                      />
                    </Card>
                  </List.Item>
                )}
              />  
            </div> 
          ) }
        </>
    )
};

export default Global;