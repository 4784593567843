import React from 'react';
import Chart from 'react-apexcharts';
import { Table, Space, Tooltip,  Card, Typography, Image, Avatar, Row, Col } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const { Title, Text, Link } = Typography;

const Global = props => {
    const columns1 = [
        {
            title: 'Миниатюра',
            dataIndex: 'img',
            key: 'img',
            render: (text) => <Image width={70} src={`${props.state.app.res}/img/${text}`} />,
            width: 100,
            ellipsis: true,
        },{
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            ellipsis: true,
            sorter: (a, b) => a.name.localeCompare(b.name),
        },{
            title: 'Описание',
            dataIndex: 'desc',
            key: 'desc',
            width: 200,
        },{
            title: 'Стоимость',
            dataIndex: 'cost',
            key: 'cost',
            width: 100,
            ellipsis: true,
            render: (text) => <p>{text} ₽</p>,
            sorter: (a, b) => a.name.localeCompare(b.name),
        },{
            title: 'Категория',
            dataIndex: 'cat',
            key: 'cat',
            width: 100,
            ellipsis: true,
            sorter: (a, b) => a.name.localeCompare(b.name),
        },{
            title: 'Податегория',
            dataIndex: 'subcat',
            key: 'subcat',
            width: 100,
            ellipsis: true,
            sorter: (a, b) => a.name.localeCompare(b.name),
        },{
            title: 'Дата начала',
            dataIndex: 'date',
            key: 'date',
            render: (text) => props.lasttime(text,'Неизвестно когда','last'),
            width: 100,
            ellipsis: true,
            
            sorter: (a, b) => a.name.localeCompare(b.name),
        },{
            title: 'Дата окончания',
            dataIndex: 'date2',
            key: 'date2',
            render: (text) => props.lasttime(text,'Неизвестно когда','last'),
            width: 100,
            ellipsis: true,
            sorter: (a, b) => a.name.localeCompare(b.name),
        }
    ];
    const columns3 = [
        {
            title: 'Запрос',
            dataIndex: 'text',
            key: 'text',
            width: 100,
            ellipsis: true,
            sorter: (a, b) => a.text.localeCompare(b.text),
        },{
            title: 'Дата добавления',
            dataIndex: 'date',
            key: 'date',
            width: 100,
            render: (text) => props.lasttime(text,'Неизвестно когда','full'),
            ellipsis: true,
            sorter: (a, b) => a.date.localeCompare(b.date),
        }
    ];
    const columns4 = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            ellipsis: true,
            sorter: (a, b) => a.name.localeCompare(b.name),
        },{
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: (text) => props.lasttime(text,'Неизвестно когда','full'),
            width: 100,
            ellipsis: true,
            sorter: (a, b) => a.date.localeCompare(b.date),
        },{
            title: 'Действия',
            key: 'action',
            render: (record) => (
                <Space key={record.id} size="middle">
                    <Tooltip title={`Скачать «${record.name}»`}><Link key="a1" onClick={() => props.api({ 'method':'rep_get', 'repid':`${record.id}` })}><DownloadOutlined /></Link></Tooltip>
                </Space>
            ),
            width: 100,
            ellipsis: true,
          }
    ];
    const columns5 = [
        {
            title: 'Описание',
            dataIndex: 'text',
            key: 'text',
            width: 100,
            sorter: (a, b) => a.text.localeCompare(b.text),
        },{
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: (text) => props.lasttime(text,'Неизвестно когда','full'),
            width: 100,
            ellipsis: true,
            sorter: (a, b) => a.date.localeCompare(b.date),
        }
    ];

    const columns6 = [
        {
            title: 'Поисковой запрос',
            dataIndex: 'dimensions',
            key: 'dimensions',
            width: 100,
            ellipsis: true,
            render: (text) => text[1]['name'],
        },{
            title: 'Поисковая система',
            dataIndex: 'dimensions',
            key: 'dimensions2',
            width: 100,
            ellipsis: true,
            render: (text) => text[0]['name'],
        },{
            title: 'Количество визитов',
            dataIndex: 'metrics',
            key: 'metrics',
            width: 100,
            ellipsis: true,
            render: (text) => text[0],
        }
    ];

    return (
        <>
            <Row style={{flexDirection:'column'}}>
                <Col span={24} style={{padding:20,maxWidth:1000,margin:'auto',flex:'1 0',width:'100%'}} className="shadowscreen" >
                    <Card className="cardnone" bordered={false} style={{ width: '100%' }} >
                        <div className="usercard">
                            <div>
                                <Avatar shape="square" size={100} src={<Image src={`${props.state.app.res}/img/${props.state.proj1[2][0]['img']}`} />} />
                            </div>
                            <div className="trans">
                                <Title className="gray2" level={4}>Информация о сайте</Title>
                                <Space direction="vertical">
                                    <Text className="gray" level={4}>Название: {props.state.proj1[2][0]['text']}</Text>
                                    <Text className="gray" level={4}>Домен: {props.state.proj1[2][0]['name']}</Text>
                                    <Text className="gray">Домен продлен до <Text className="gray" strong>{props.lasttime(props.state.proj1[2][0]['date'],'Неизвестно когда','last')}</Text></Text>
                                    <Link className="gray" target="_blank" rel="noopener noreferrer" href={`https://${props.state.proj1[2][0]['name']}`}>{`Перейти на сайт`} </Link>
                                </Space>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Title className="gray" style={{marginTop:50}} level={4}>Тарифы</Title>
                <Col span={24} style={{padding:20}} className="shadowscreen" >
                    <Table dataSource={props.state.proj1[2][1]} locale={{ emptyText: <span className="tableempty">Список пуст</span>}} scroll={{ x: 800 }} rowKey="table2" columns={columns1}></Table>
                </Col>
                <Title className="gray" style={{marginTop:50}} level={4}>Разовые услуги</Title>
                <Col span={24} style={{padding:20}} className="shadowscreen" >
                    <Table dataSource={props.state.proj1[2][2]} locale={{ emptyText: <span className="tableempty">Список пуст</span>}} scroll={{ x: 800 }} rowKey="table2" columns={columns1}></Table>
                </Col>
                <Title className="gray" style={{marginTop:50}} level={4}>SEO</Title>
                <Col span={24} style={{padding:20}} className="shadowscreen" >
                    <Table dataSource={props.state.proj1[2][3]} locale={{ emptyText: <span className="tableempty">Список пуст</span>}} scroll={{ x: 800 }} rowKey="table2" columns={columns3}></Table>
                </Col>
                <Title className="gray" style={{marginTop:50}} level={4}>Отчёты</Title>
                <Col span={24} style={{padding:20}} className="shadowscreen" >
                    <Table dataSource={props.state.proj1[2][4]} locale={{ emptyText: <span className="tableempty">Список пуст</span>}} scroll={{ x: 800 }} rowKey="table2" columns={columns4}></Table>
                </Col>
                <Title className="gray" style={{marginTop:50}} level={4}>История работ</Title>
                <Col span={24} style={{padding:20}} className="shadowscreen" >
                    <Table dataSource={props.state.proj1[2][5]} locale={{ emptyText: <span className="tableempty">Список пуст</span>}} scroll={{ x: 800 }} rowKey="table2" columns={columns5}></Table>
                </Col>
            </Row>
            {props.state.proj1[2][6].length > 0 &&
                <>
                    <Row className="wrow">
                        <Col className="wcol shadowscreen" >
                            <Title className="gray" level={4}>Визиты за последний месяц</Title>
                            <Chart options={props.state.proj1[2][6][0].options} series={props.state.proj1[2][6][0].series1} type="area" width={props.state.width} height={350} />
                        </Col>
                        <Col className="wcol shadowscreen" >
                            <Title className="gray" level={4}>Просмотры за последний месяц</Title>
                            <Chart options={props.state.proj1[2][6][0].options} series={props.state.proj1[2][6][0].series2} type="area" width={props.state.width} height={350} />
                        </Col>
                        <Col className="wcol shadowscreen" >
                            <Title className="gray" level={4}>Посетители за последний месяц</Title>
                            <Chart options={props.state.proj1[2][6][0].options} series={props.state.proj1[2][6][0].series3} type="area" width={props.state.width} height={350} />
                        </Col>
                        <Col className="wcol shadowscreen" >
                            <Title className="gray" level={4}>Отказы % за последний месяц</Title>
                            <Chart options={props.state.proj1[2][6][0].options} series={props.state.proj1[2][6][0].series4} type="area" width={props.state.width} height={350} />
                        </Col>
                        <Col className="wcol shadowscreen" >
                            <Title className="gray" level={4}>Глубина просмотра за последний месяц</Title>
                            <Chart options={props.state.proj1[2][6][0].options} series={props.state.proj1[2][6][0].series5} type="area" width={props.state.width} height={350} />
                        </Col>
                        <Col className="wcol shadowscreen" >
                            <Title className="gray" level={4}>Время на сайте за последний месяц</Title>
                            <Chart options={props.state.proj1[2][6][0].options} series={props.state.proj1[2][6][0].series6} type="area" width={props.state.width} height={350} />
                        </Col>
                        <Col className="wcol shadowscreen" >
                            <Title className="gray" level={4}>Поисковые запросы за последний месяц</Title>
                            <Table locale={{ emptyText: <span className="tableempty">Список пуст</span>}} className="metrika" dataSource={props.state.proj1[2][6][2].data} scroll={{ x: 500, y: 500 }} rowKey="table" columns={columns6}></Table>
                        </Col>
                    </Row>
                </>
            }
        </>
    )
};

export default Global;