import React from 'react';
import { Layout, Form, Input, Button, Checkbox } from 'antd';
import {  EyeTwoTone, EyeInvisibleOutlined  } from '@ant-design/icons';
const { Content, Footer  } = Layout;

const Global = props => {
    return (
        <Layout style={{ minHeight: "100vh" }} className="bg-auth">
            <Content style={{ margin: '24px 16px 0' }} >
                <div className="authpage">
                <div className="authtitle">
                    <div className="logo" />
                    <p>ЛИЧНЫЙ КАБИНЕТ</p>
                    <p>КЛИЕНТА</p>
                </div>
                <div className="authpageblock">
                    <Form name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={props.f_Login} >
                    <Form.Item name="username" rules={[{ required: true, message: 'Пожалуйста, введите логин!' }]} >
                        <Input className="inauth" placeholder="Логин" />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: 'Пожалуйста, введине пароль!' }]} >
                        <Input.Password className="inauth" type="password" placeholder="Пароль" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                    </Form.Item>
                    <Form.Item className="auth_remember">
                        <Form.Item name="remember" noStyle><Checkbox>Запомнить меня</Checkbox></Form.Item>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" className="login-form-button inauths">Вход</Button>
                    </Form.Item>
                    </Form>
                </div>
                </div>
            </Content>
            <Footer className="gradient" style={{ textAlign: 'center' }}>RUS2 Design ©2021 Created by RUS2</Footer>
        </Layout>
    )
};

export default Global;