import React from 'react';
import { Layout, Avatar, Space, Menu, PageHeader, Dropdown } from 'antd';
import {  ImportOutlined, UserOutlined  } from '@ant-design/icons';
const { Header  } = Layout;

const usermenu = props => {
    return (
        <Menu>
            <Menu.ItemGroup title="Основное">
                <Menu.Item key="-2" onClick={(e) => props.menu(e)} icon={<UserOutlined />}>
                    Профиль
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="Действия">
                <Menu.Item danger key="0" onClick={(e) => props.menu(e)} icon={<ImportOutlined />}>
                    Выход
                </Menu.Item>
            </Menu.ItemGroup>
        </Menu>
      )
};

const Global = props => {
    return (
        <Header className="site-layout-sub-header-background" style={{ padding: 0 }}>
            <PageHeader
                className="site-page-header"
                title=
                    {!props.state.loading ? 
                        <span style={{color:'#575757'}}>{props.state.menu[props.state.activemenu]}</span>
                    :
                        <span style={{color:'#575757'}}>Обновление</span>
                    }
                extra={[
                    <Space key="loadkey">
                        {props.state.profile.name ? 
                            <>
                                <Dropdown overlay={usermenu(props)} trigger={['click']} >
                                    <Space>
                                    {props.state.profile.ava !== "" ? 
                                        <Avatar src={`${props.state.app.res}/img/${props.state.profile.ava}`} />
                                    :
                                        <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                    }
                                    </Space>
                                </Dropdown>
                            </>
                        :
                            <>
                                Загрузка...
                                <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                            </>
                        }
                    </Space>,
                ]}
            />
        </Header>
    )
};

export default Global;