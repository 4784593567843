import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Layout, Menu, Divider } from 'antd';
import { HomeOutlined, ImportOutlined, SettingOutlined, GlobalOutlined, FireOutlined, TeamOutlined, FileDoneOutlined  } from '@ant-design/icons';
const { Sider } = Layout;

const Global = props => {
    return (
        <Router>
            <Sider className="menuscreen"  theme="light" breakpoint="lg" collapsedWidth="0">
                <div className="logo" />
                <Menu 
                    mode="inline" 
                    defaultSelectedKeys={props.state.activemenu} 
                    defaultOpenKeys={['1']}
                >
                    <Menu.Item key="-1" onClick={(e) => props.menu(e)} icon={<HomeOutlined />}><Link to="/">Главная</Link></Menu.Item>
                    {props.state.visible[0] && <Menu.Item key="1" onClick={(e) => props.menu(e)} icon={<GlobalOutlined />}><Link to="/">Сайты</Link></Menu.Item>}
                    {props.state.visible[1] && <Menu.Item key="2" onClick={(e) => props.menu(e)} icon={<TeamOutlined />}><Link to="/">Соц сети</Link></Menu.Item>}
                    <Menu.Item key="-3" onClick={(e) => props.menu(e)} icon={<FileDoneOutlined />}><Link to="/">Документы</Link></Menu.Item>
                    <Menu.Item key="-4" onClick={(e) => props.menu(e)} icon={<FireOutlined />}><Link to="/">Услуги</Link></Menu.Item>
                    <Divider className="dividermenu" />
                    <Menu.Item key="-2" onClick={(e) => props.menu(e)} icon={<SettingOutlined />}><Link to="/">Настройки</Link></Menu.Item>
                    <Menu.Item key="0"onClick={(e) => props.menu(e)} icon={<ImportOutlined />}><Link to="/">Выйти</Link></Menu.Item>
                </Menu>
            </Sider>
        </Router>
    )
};

export default Global;