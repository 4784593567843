import React from 'react';
import Chart from 'react-apexcharts';
import { Result, Avatar, Typography, Row, Col } from 'antd';

const { Title } = Typography;

const Global = props => {

    if (props.state.home[1]) {
        setTimeout(() => {props.setState( {width:'100%'} )}, 1000);
    }

    return (
            <>
                <Result
                    className="shadowscreen"
                    icon={<Avatar size={128} src={`${props.state.app.res}/img/${props.state.profile.ava}`} />}
                    title={props.hello()}
                    subTitle="Вы находитесь в личном кабинете клиента! У нас можно посмотреть список оказанных услуг, а также историю ведения сайтов и социальных сетей."
                />
                {props.state.home[1] &&
                    <>
                        <Title className="textcenter gray" style={{marginTop:50}} level={2}>Статистика сайта {props.state.home[0]['name']}</Title>
                            <Row className="wrow">
                                <Col className="wcol shadowscreen" >
                                    <Title className="gray" level={4}>Визиты за последний месяц</Title>
                                    <Chart options={props.state.home[1][0].options} series={props.state.home[1][0].series1} type="area" width={props.state.width} height={350} />
                                </Col>
                                <Col className="wcol shadowscreen" >
                                    <Title className="gray" level={4}>Просмотры за последний месяц</Title>
                                    <Chart options={props.state.home[1][0].options} series={props.state.home[1][0].series2} type="area" width={props.state.width} height={350} />
                                </Col>
                                <Col className="wcol shadowscreen" >
                                    <Title className="gray" level={4}>Посетители за последний месяц</Title>
                                    <Chart options={props.state.home[1][0].options} series={props.state.home[1][0].series3} type="area" width={props.state.width} height={350} />
                                </Col>
                                <Col className="wcol shadowscreen" >
                                    <Title className="gray" level={4}>Отказы % за последний месяц</Title>
                                    <Chart options={props.state.home[1][0].options} series={props.state.home[1][0].series4} type="area" width={props.state.width} height={350} />
                                </Col>
                                <Col className="wcol shadowscreen" >
                                    <Title className="gray" level={4}>Глубина просмотра за последний месяц</Title>
                                    <Chart options={props.state.home[1][0].options} series={props.state.home[1][0].series5} type="area" width={props.state.width} height={350} />
                                </Col>
                                <Col className="wcol shadowscreen" >
                                    <Title className="gray" level={4}>Время на сайте за последний месяц</Title>
                                    <Chart options={props.state.home[1][0].options} series={props.state.home[1][0].series6} type="area" width={props.state.width} height={350} />
                                </Col>
                            </Row>
                    </>
                }
            </>
    )
};

export default Global;