import React from 'react';
import { Result } from 'antd';
import {  TeamOutlined  } from '@ant-design/icons';

const Global = props => {
    return  (
        <Result
            icon={<TeamOutlined />}
            title="Данный раздел появится позже"
        />
    )
};

export default Global;